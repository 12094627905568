import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';

export const clientNames = {
  internal: 'internal',
  shopify: 'shopify',
};

const internalLink = new HttpLink({
  uri:
    process.env.NEXT_PUBLIC_INTERNAL_GRAPHQL_ENDPOINT ||
    'http://localhost:8081/graphql',
  credentials: 'include',
});

const shopifyLink = new HttpLink({
  uri: 'https://rufflog.myshopify.com/api/2022-04/graphql.json',
  headers: {
    'X-Shopify-Storefront-Access-Token': 'd107d459ff7a6480c62dccb9a2b80ae4',
  },
});

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === clientNames.shopify,
    shopifyLink,
    internalLink,
  ),
  cache: new InMemoryCache(),
});

export default client;
