// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT =
  process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV || 'development';

Sentry.init({
  ignoreErrors: [
    'Text content does not match server-rendered HTML.',
    'There was an error while hydrating.',
    'Hydration failed',
  ],
  enabled: process.env.NODE_ENV !== 'local',
  dsn:
    SENTRY_DSN ||
    'https://40ff4615d07c4905b41b657a53fcdcfa@o1247318.ingest.sentry.io/6407287',
  // Adjust this value in production, or use tracesSampler for greater control
  environment: SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.2,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
