import dayjs from 'dayjs';

type MetaField = { id: string; value: string } | null;

type HasPreOrderContext = {
  availableForPreOrder?: MetaField;
  shippingSchedule?: MetaField;
  releasedAt?: MetaField;
};

export const isInPreOrder = (product: HasPreOrderContext): boolean => {
  const { availableForPreOrder, shippingSchedule, releasedAt } = product;
  if (!availableForPreOrder || !shippingSchedule || !releasedAt) {
    return false;
  }
  if (dayjs().isAfter(dayjs(releasedAt.value))) {
    return false;
  }
  return (
    availableForPreOrder.value === 'true' && shippingSchedule.value.length > 0
  );
};
