import Head from 'next/head';
import type { FC, ReactNode } from 'react';

import { store } from '~/config/store';

import type { OpenGraphImageProps } from './OpenGraphImage';
import { OpenGraphImage } from './OpenGraphImage';

interface Props {
  title?: string;
  description?: string;
  robots?: string;
  openGraph?: {
    title?: string;
    type?: string;
    locale?: string;
    description?: string;
    site_name?: string;
    url?: string;
    images?: OpenGraphImageProps[];
  };
  children?: ReactNode;
}

export const SEO: FC<Props> = ({
  title,
  description,
  openGraph,
  robots,
  children,
}) => (
  <Head>
    <title key="title">
      {title ? `${store.titleTemplate.replace(/%s/g, title)}` : store.title}
    </title>
    <meta
      key="description"
      name="description"
      content={description || store.description}
    />
    <meta
      key="og:type"
      property="og:type"
      content={openGraph?.type ?? store.openGraph.type}
    />
    <meta
      key="og:title"
      property="og:title"
      content={
        openGraph?.title ?? store.openGraph.title ?? title ?? store.title
      }
    />
    <meta
      key="og:description"
      property="og:description"
      content={
        openGraph?.description ??
        store.openGraph.description ??
        description ??
        store.description
      }
    />
    <meta
      key="og:site_name"
      property="og:site_name"
      content={openGraph?.site_name ?? store.openGraph.site_name}
    />
    <meta
      key="og:url"
      property="og:url"
      content={openGraph?.url ?? store.openGraph.url}
    />
    {openGraph?.locale && (
      <meta key="og:locale" property="og:locale" content={openGraph.locale} />
    )}
    {openGraph?.images?.length ? (
      openGraph.images.map((img, index) => (
        <OpenGraphImage key={index} index={index} image={img} />
      ))
    ) : (
      <OpenGraphImage image={store.openGraph.images[0]} />
    )}
    <meta key="robots" name="robots" content={robots ?? 'index,follow'} />
    <meta key="googlebot" name="googlebot" content={robots ?? 'index,follow'} />
    <meta
      name="google-site-verification"
      content="iNQFFgAAcPjHW0_1ya90uqXsFA5yRTJotfIjp-kNnwo"
    />
    <meta
      name="facebook-domain-verification"
      content="ku7qr5etzzjwsqcxbath00m4qeh5i1"
    />
    {children}
  </Head>
);
