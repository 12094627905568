import type { FC, ReactNode } from 'react';
import { createContext, useState, useEffect, useContext } from 'react';

import { useToast } from '@chakra-ui/react';

import { clientNames } from '~/graphql/apollo';
import type {
  ShopifyCartItem,
  UpdateShopifyCartItemsInput,
} from '~/graphql/internal/generated';
import { useUpdateShopifyCartItemsMutation } from '~/graphql/internal/generated';
import { useShopifyCartQuery } from '~/graphql/internal/generated';
import { useProductVariantLazyQuery } from '~/graphql/shopify/generated';
import { isInPreOrder } from '~/utils/pre_order';

type CartMode = 'empty' | 'normal' | 'preOrder';

type CartContextProps = {
  mode: CartMode;
  pristine: boolean;
  updating: boolean;
  cartId?: string;
  items: ShopifyCartItem[];
  updateCart: (vars: UpdateCartVariables) => void;
};

const defaultContext: CartContextProps = {
  mode: 'empty',
  pristine: true,
  updating: false,
  items: [],
  updateCart: () => {},
};

const CartContext = createContext<CartContextProps>(defaultContext);

type Props = {
  children?: ReactNode;
};

type UpdateCartVariables = {
  variantId: string;
  quantity: number;
};

const applyCart = (
  vars: UpdateCartVariables,
  items: ShopifyCartItem[],
): UpdateShopifyCartItemsInput => {
  if (items.find((i) => i.variantId === vars.variantId)) {
    const next = items.map((i) =>
      i.variantId === vars.variantId
        ? { variantId: i.variantId, quantity: vars.quantity }
        : { variantId: i.variantId, quantity: i.quantity },
    );
    return {
      items: next.filter((n) => n.quantity !== 0),
    };
  }
  return {
    items: items
      .map(({ variantId, quantity }) => ({ variantId, quantity }))
      .concat([vars]),
  };
};

export const CartContextProvider: FC<Props> = ({ children }) => {
  const toast = useToast();
  const [cartId, setCartId] = useState<string>();
  const [items, setItems] = useState<ShopifyCartItem[]>([]);
  const [mode, setMode] = useState<CartMode>('empty');
  const { data, loading, refetch } = useShopifyCartQuery({
    fetchPolicy: 'no-cache',
  });
  const [updating, setUpdating] = useState<boolean>(false);
  const [fetchVariant] = useProductVariantLazyQuery();
  const [update] = useUpdateShopifyCartItemsMutation({
    onCompleted: () => {
      refetch();
      setUpdating(false);
    },
    onError: () => {
      setUpdating(false);
      toast({
        title: '',
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    },
  });
  const updateCart = async (vars: UpdateCartVariables) => {
    const input = applyCart(vars, items);
    setUpdating(true);
    update({ variables: { input } });
  };
  const value = {
    mode,
    pristine: !cartId,
    items,
    cartId,
    updating,
    updateCart,
  };
  useEffect(() => {
    if (loading) {
      return;
    }
    setCartId(data?.shopifyCartQuery.id);
    setItems(
      (data?.shopifyCartQuery.shopifyCartItems as ShopifyCartItem[]) || [],
    );
  }, [data, loading]);

  useEffect(() => {
    if (items.length === 0) {
      return;
    }
    fetchVariant({
      variables: {
        ids: items.map((i) => i.variantId),
      },
      context: {
        clientName: clientNames.shopify,
      },
      onCompleted(data) {
        const isPreOrderMode =
          data.nodes.filter((n) => {
            if (n?.__typename !== 'ProductVariant') {
              return false;
            }
            return isInPreOrder(n.product);
          }).length > 0;
        console.log({ isPreOrderMode });

        setMode(isPreOrderMode ? 'preOrder' : 'normal');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCart = () => useContext(CartContext);
