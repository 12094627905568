export const store = {
  url: process.env.NEXT_PUBLIC_STORE_URL || '',
  title: 'RUFFLOG ラフロッグ',
  titleTemplate: '%s | RUFFLOG ラフロッグ',
  description:
    'RUFFLOGは自由にゴルフのファッションを楽しみたい若者向けのゴルフブランドを多数取り扱うゴルフアパレルのセレクトショップです。',
  openGraph: {
    title: 'RUFFLOG ラフロッグ',
    description:
      'RUFFLOGは自由にゴルフのファッションを楽しみたい若者向けのゴルフブランドを多数取り扱うゴルフアパレルのセレクトショップです。',
    type: 'website',
    url: 'https://rufflog.jp',
    site_name: 'RUFFLOG',
    images: [
      {
        url: '/card.png',
        width: '800',
        height: '600',
        alt: 'RUFFLOG',
      },
    ],
  },
};
