import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext, createContext } from 'react';

import axios from 'axios';

import type { CustomerAccessToken } from '~/graphql/shopify/generated';

type AuthContextProps = {
  pristine: boolean;
  authorization?: CustomerAccessToken;
  refetch: () => void;
};

const defaultContext: AuthContextProps = {
  pristine: true,
  refetch: () => {},
};

const AuthContext = createContext<AuthContextProps>(defaultContext);

type Props = {
  children?: ReactNode;
};

export const AuthContextProvider: FC<Props> = ({ children }) => {
  const [pristine, setPristine] = useState<boolean>(true);
  const [authorization, setAuthorization] = useState<CustomerAccessToken>();
  const fetchToken = async () => {
    const res = await axios
      .get<CustomerAccessToken>('/api/me')
      .then((res) => res.data);
    if (!res) {
      return;
    }
    setAuthorization(res);
  };
  useEffect(() => {
    (async () => {
      await fetchToken();
      setPristine(false);
    })();
  }, []);
  const value = {
    pristine,
    authorization,
    refetch: fetchToken,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
