import type { FC } from 'react';

import { SEO } from '~/components/common/SEO';

const Head: FC = () => {
  return (
    <SEO>
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
      <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
      <meta
        name="facebook-domain-verification"
        content="9ianvpew44yxrhs9j9tso425zqwo3h"
      />
    </SEO>
  );
};

export default Head;
