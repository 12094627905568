import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** Datetime adjusted for prisma timezone deviation issue */
  Datetime: any;
  /** Represents NULL values */
  Void: any;
};

export type Mutation = {
  __typename?: 'Mutation';
  sendInquiry?: Maybe<Ok>;
  setShopifyCartCheckoutId?: Maybe<Ok>;
  updateShopifyCartItems?: Maybe<Ok>;
};

export type MutationSendInquiryArgs = {
  input: SendInquiryInput;
};

export type MutationSetShopifyCartCheckoutIdArgs = {
  checkoutId: Scalars['String'];
};

export type MutationUpdateShopifyCartItemsArgs = {
  input: UpdateShopifyCartItemsInput;
};

export enum ProductFilter {
  Collection = 'collection',
  Default = 'default',
  Tag = 'tag',
}

export enum ProductOrder {
  Id = 'id',
  Price = 'price',
  PublishedAt = 'publishedAt',
  Sales = 'sales',
  Stocks = 'stocks',
}

export type Query = {
  __typename?: 'Query';
  shopifyCartQuery: ShopifyCart;
  shopifyProducts: Array<ShopifyProduct>;
};

export type QueryShopifyProductsArgs = {
  input?: InputMaybe<ShopifyProductsInput>;
};

export enum Sort {
  Asc = 'asc',
  Desc = 'desc',
}

export type Ok = {
  __typename?: 'ok';
  msg: Scalars['String'];
};

export type SendInquiryInput = {
  body: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  tel: Scalars['String'];
};

export type ShopifyCart = {
  __typename?: 'shopifyCart';
  cookieToken: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isClosed: Scalars['Boolean'];
  shopifyCartItems: Array<ShopifyCartItem>;
};

export type ShopifyCartItem = {
  __typename?: 'shopifyCartItem';
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  shopifyCartId: Scalars['String'];
  variantId: Scalars['String'];
};

export type ShopifyProduct = {
  __typename?: 'shopifyProduct';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ShopifyProductsInput = {
  productName?: InputMaybe<Scalars['String']>;
  collectionFilter?: InputMaybe<Scalars['String']>;
  tagFilter?: InputMaybe<Array<Scalars['String']>>;
  order?: InputMaybe<ProductOrder>;
  sort?: InputMaybe<Sort>;
};

export type UpdateShopifyCartItemInput = {
  quantity: Scalars['Int'];
  variantId: Scalars['String'];
};

export type UpdateShopifyCartItemsInput = {
  items: Array<UpdateShopifyCartItemInput>;
};

export type SendInquiryMutationVariables = Exact<{
  input: SendInquiryInput;
}>;

export type SendInquiryMutation = {
  __typename?: 'Mutation';
  sendInquiry?: { __typename?: 'ok'; msg: string } | null;
};

export type SetShopifyCartCheckoutIdMutationVariables = Exact<{
  checkoutId: Scalars['String'];
}>;

export type SetShopifyCartCheckoutIdMutation = {
  __typename?: 'Mutation';
  setShopifyCartCheckoutId?: { __typename?: 'ok'; msg: string } | null;
};

export type UpdateShopifyCartItemsMutationVariables = Exact<{
  input: UpdateShopifyCartItemsInput;
}>;

export type UpdateShopifyCartItemsMutation = {
  __typename?: 'Mutation';
  updateShopifyCartItems?: { __typename?: 'ok'; msg: string } | null;
};

export type ShopifyCartQueryVariables = Exact<{ [key: string]: never }>;

export type ShopifyCartQuery = {
  __typename?: 'Query';
  shopifyCartQuery: {
    __typename?: 'shopifyCart';
    id: string;
    shopifyCartItems: Array<{
      __typename?: 'shopifyCartItem';
      id: string;
      variantId: string;
      quantity: number;
    }>;
  };
};

export type ShopifyProductsQueryVariables = Exact<{
  input?: InputMaybe<ShopifyProductsInput>;
}>;

export type ShopifyProductsQuery = {
  __typename?: 'Query';
  shopifyProducts: Array<{ __typename?: 'shopifyProduct'; id: string }>;
};

export const SendInquiryDocument = gql`
  mutation SendInquiry($input: sendInquiryInput!) {
    sendInquiry(input: $input) {
      msg
    }
  }
`;
export type SendInquiryMutationFn = Apollo.MutationFunction<
  SendInquiryMutation,
  SendInquiryMutationVariables
>;

/**
 * __useSendInquiryMutation__
 *
 * To run a mutation, you first call `useSendInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInquiryMutation, { data, loading, error }] = useSendInquiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInquiryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInquiryMutation,
    SendInquiryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendInquiryMutation, SendInquiryMutationVariables>(
    SendInquiryDocument,
    options,
  );
}
export type SendInquiryMutationHookResult = ReturnType<
  typeof useSendInquiryMutation
>;
export type SendInquiryMutationResult =
  Apollo.MutationResult<SendInquiryMutation>;
export type SendInquiryMutationOptions = Apollo.BaseMutationOptions<
  SendInquiryMutation,
  SendInquiryMutationVariables
>;
export const SetShopifyCartCheckoutIdDocument = gql`
  mutation SetShopifyCartCheckoutId($checkoutId: String!) {
    setShopifyCartCheckoutId(checkoutId: $checkoutId) {
      msg
    }
  }
`;
export type SetShopifyCartCheckoutIdMutationFn = Apollo.MutationFunction<
  SetShopifyCartCheckoutIdMutation,
  SetShopifyCartCheckoutIdMutationVariables
>;

/**
 * __useSetShopifyCartCheckoutIdMutation__
 *
 * To run a mutation, you first call `useSetShopifyCartCheckoutIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShopifyCartCheckoutIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShopifyCartCheckoutIdMutation, { data, loading, error }] = useSetShopifyCartCheckoutIdMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *   },
 * });
 */
export function useSetShopifyCartCheckoutIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetShopifyCartCheckoutIdMutation,
    SetShopifyCartCheckoutIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetShopifyCartCheckoutIdMutation,
    SetShopifyCartCheckoutIdMutationVariables
  >(SetShopifyCartCheckoutIdDocument, options);
}
export type SetShopifyCartCheckoutIdMutationHookResult = ReturnType<
  typeof useSetShopifyCartCheckoutIdMutation
>;
export type SetShopifyCartCheckoutIdMutationResult =
  Apollo.MutationResult<SetShopifyCartCheckoutIdMutation>;
export type SetShopifyCartCheckoutIdMutationOptions =
  Apollo.BaseMutationOptions<
    SetShopifyCartCheckoutIdMutation,
    SetShopifyCartCheckoutIdMutationVariables
  >;
export const UpdateShopifyCartItemsDocument = gql`
  mutation UpdateShopifyCartItems($input: updateShopifyCartItemsInput!) {
    updateShopifyCartItems(input: $input) {
      msg
    }
  }
`;
export type UpdateShopifyCartItemsMutationFn = Apollo.MutationFunction<
  UpdateShopifyCartItemsMutation,
  UpdateShopifyCartItemsMutationVariables
>;

/**
 * __useUpdateShopifyCartItemsMutation__
 *
 * To run a mutation, you first call `useUpdateShopifyCartItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopifyCartItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopifyCartItemsMutation, { data, loading, error }] = useUpdateShopifyCartItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopifyCartItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShopifyCartItemsMutation,
    UpdateShopifyCartItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShopifyCartItemsMutation,
    UpdateShopifyCartItemsMutationVariables
  >(UpdateShopifyCartItemsDocument, options);
}
export type UpdateShopifyCartItemsMutationHookResult = ReturnType<
  typeof useUpdateShopifyCartItemsMutation
>;
export type UpdateShopifyCartItemsMutationResult =
  Apollo.MutationResult<UpdateShopifyCartItemsMutation>;
export type UpdateShopifyCartItemsMutationOptions = Apollo.BaseMutationOptions<
  UpdateShopifyCartItemsMutation,
  UpdateShopifyCartItemsMutationVariables
>;
export const ShopifyCartDocument = gql`
  query ShopifyCart {
    shopifyCartQuery {
      id
      shopifyCartItems {
        id
        variantId
        quantity
      }
    }
  }
`;

/**
 * __useShopifyCartQuery__
 *
 * To run a query within a React component, call `useShopifyCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopifyCartQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ShopifyCartQuery,
    ShopifyCartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShopifyCartQuery, ShopifyCartQueryVariables>(
    ShopifyCartDocument,
    options,
  );
}
export function useShopifyCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopifyCartQuery,
    ShopifyCartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShopifyCartQuery, ShopifyCartQueryVariables>(
    ShopifyCartDocument,
    options,
  );
}
export type ShopifyCartQueryHookResult = ReturnType<typeof useShopifyCartQuery>;
export type ShopifyCartLazyQueryHookResult = ReturnType<
  typeof useShopifyCartLazyQuery
>;
export type ShopifyCartQueryResult = Apollo.QueryResult<
  ShopifyCartQuery,
  ShopifyCartQueryVariables
>;
export const ShopifyProductsDocument = gql`
  query ShopifyProducts($input: shopifyProductsInput) {
    shopifyProducts(input: $input) {
      id
    }
  }
`;

/**
 * __useShopifyProductsQuery__
 *
 * To run a query within a React component, call `useShopifyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifyProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ShopifyProductsQuery,
    ShopifyProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShopifyProductsQuery, ShopifyProductsQueryVariables>(
    ShopifyProductsDocument,
    options,
  );
}
export function useShopifyProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopifyProductsQuery,
    ShopifyProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShopifyProductsQuery,
    ShopifyProductsQueryVariables
  >(ShopifyProductsDocument, options);
}
export type ShopifyProductsQueryHookResult = ReturnType<
  typeof useShopifyProductsQuery
>;
export type ShopifyProductsLazyQueryHookResult = ReturnType<
  typeof useShopifyProductsLazyQuery
>;
export type ShopifyProductsQueryResult = Apollo.QueryResult<
  ShopifyProductsQuery,
  ShopifyProductsQueryVariables
>;
