import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import { createContext, useState, useEffect, useContext } from 'react';

type SlideMenuContextProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  depth: number;
  visibleIds: string[];
  appendVisibleId: (id: string) => void;
  dropLastVisibleId: () => void;
  reset: () => void;
};

const defaultContext: SlideMenuContextProps = {
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  depth: 0,
  visibleIds: [],
  appendVisibleId: () => {},
  dropLastVisibleId: () => {},
  reset: () => {},
};

const SlideMenuContext = createContext<SlideMenuContextProps>(defaultContext);

type Props = {
  children?: ReactNode;
};

export const SlideMenuContextProvider: FC<Props> = ({ children }) => {
  const { pathname } = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [depth, setDepth] = useState<number>(0);
  const [visibleIds, setVisibleIds] = useState<string[]>([]);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const appendVisibleId = (id: string) => {
    setVisibleIds((current) => current.concat(id));
    setDepth((current) => current + 1);
  };
  const dropLastVisibleId = () => {
    setVisibleIds((current) => {
      const next = current.concat([]);
      next.pop();
      return next;
    });
    setDepth((current) => current - 1);
  };
  const reset = () => {
    setDepth(0);
    setVisibleIds([]);
  };
  const value = {
    isOpen,
    onOpen,
    onClose,
    depth,
    visibleIds,
    appendVisibleId,
    dropLastVisibleId,
    reset,
  };

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);
  return (
    <SlideMenuContext.Provider value={value}>
      {children}
    </SlideMenuContext.Provider>
  );
};

export const useSlideMenu = () => useContext(SlideMenuContext);
