import { useRouter } from 'next/router';
import type { ChangeEvent, FC, ReactNode } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext, createContext } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

import locales from '~/data/locales.json';

type I18nContextProps = {
  showModal: () => void;
};

const defaultContext: I18nContextProps = {
  showModal: () => {},
};

const I18nContext = createContext<I18nContextProps>(defaultContext);

type Props = {
  children?: ReactNode;
};

const availableLocaleCodes = locales.map((l) => l.locale);

export const I18nContextProvider: FC<Props> = ({ children }) => {
  const isClient = typeof window !== 'undefined';
  const { locale: routingLocale, defaultLocale, push, asPath } = useRouter();
  const [locale, setLocale] = useState<string>('');
  const modal = useDisclosure();
  const onCloseWithoutSetting = () => {
    window.localStorage.setItem(
      'skipLanguageSetting',
      dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    );
    modal.onClose();
  };
  const onSelectLocale = (e: ChangeEvent<HTMLSelectElement>) => {
    setLocale(e.target.value);
  };
  const onSubmit = () => {
    push(asPath, asPath, {
      locale,
    });
    modal.onClose();
  };
  const showModal = useCallback(() => {
    setLocale(() => {
      if (routingLocale && routingLocale !== defaultLocale) {
        return routingLocale;
      }
      const loc = navigator.language.replace('-', '_');
      return availableLocaleCodes.includes(loc) ? loc : '';
    });
    modal.onOpen();
  }, [modal, routingLocale, defaultLocale]);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    const skipLanguageSetting = window.localStorage.getItem(
      'skipLanguageSetting',
    );
    const shouldIgnore =
      skipLanguageSetting && dayjs().isBefore(dayjs(skipLanguageSetting));
    if (shouldIgnore) {
      return;
    }
    if (['ja-JP', 'ja'].includes(navigator.language)) {
      return;
    }
    if (routingLocale !== defaultLocale) {
      return;
    }
    showModal();
  }, [isClient, modal, defaultLocale, routingLocale, showModal]);
  const value = {
    showModal,
  };
  return (
    <I18nContext.Provider value={value}>
      {children}
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
        <ModalOverlay onClick={onCloseWithoutSetting} />
        <ModalContent>
          <ModalHeader>Select Language</ModalHeader>
          <ModalCloseButton onClick={onCloseWithoutSetting} />
          <ModalBody>
            {modal.isOpen && (
              <Select defaultValue={locale} onChange={onSelectLocale}>
                <option style={{ display: 'none' }} value="" selected disabled>
                  Select Location/Currency
                </option>
                {locales.map((l) => (
                  <option key={l.name} value={l.locale}>
                    {l.name} / {l.currency}
                  </option>
                ))}
              </Select>
            )}
            <Button w="full" my={4} type="submit" onClick={onSubmit}>
              Select
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </I18nContext.Provider>
  );
};

export const useI18n = () => useContext(I18nContext);
