import type { FC } from 'react';
import { Fragment } from 'react';

import { store } from '~/config/store';

export type OpenGraphImageProps = {
  url?: string;
  width?: string;
  height?: string;
  alt?: string;
};

type Props = {
  index?: number;
  image: OpenGraphImageProps;
};

export const OpenGraphImage: FC<Props> = ({
  image: { url, width, height, alt },
  index = 0,
}) => {
  // generate full URL for OG image url with store base URL
  const imgUrl = store.url ? new URL(url!, store.url).toString() : url;
  return (
    <Fragment key={`og:image:${index}`}>
      <meta
        key={`og:image:url:${index}`}
        property="og:image"
        content={imgUrl}
      />
      <meta
        key={`og:image:width:${index}`}
        property="og:image:width"
        content={width}
      />
      <meta
        key={`og:image:height:${index}`}
        property="og:image:height"
        content={height}
      />
      <meta
        key={`og:image:alt:${index}`}
        property="og:image:alt"
        content={alt}
      />
    </Fragment>
  );
};
