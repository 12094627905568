import type { AppPropsWithLayout } from 'next/app';
import type { FC } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { appWithTranslation } from 'next-i18next';
import ProgressBar from 'nextjs-progressbar';

import Head from '~/components/common/Head';
import { AuthContextProvider } from '~/context/auth';
import { CartContextProvider } from '~/context/cart';
import { I18nContextProvider } from '~/context/i18n';
import { SlideMenuContextProvider } from '~/context/slide_menu';
import client from '~/graphql/apollo';
import { theme } from '~/theme';

const MyApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <>
      <Head />
      <ChakraProvider theme={theme}>
        <SlideMenuContextProvider>
          <ApolloProvider client={client}>
            <AuthContextProvider>
              <CartContextProvider>
                <I18nContextProvider>
                  <ProgressBar showOnShallow color="#000" height={3} />
                  {getLayout(<Component {...pageProps} />)}
                </I18nContextProvider>
              </CartContextProvider>
            </AuthContextProvider>
          </ApolloProvider>
        </SlideMenuContextProvider>
      </ChakraProvider>
    </>
  );
};

export default appWithTranslation(MyApp);
